import { Fragment, useEffect, useState } from "react";
import Spinner from "../Loaders/Spinner";
import axios from "axios";
import {
    DeleteProjectCheckMapping,
    DeleteUserProjectRoleMapping,
    GetCheckNameList,
    GetProjectList,
    InsertProjectCheckMapping,
    MapUserToProject,
    Roles,
    UserDetailsByProjectId
} from "../EndPoint";
import { MenuItem, Select } from "@mui/material";
import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Toast from "../Toaster/Toast";
import Alert from "@mui/material/Alert";


export default function MapUser() {
    const [projectId, setProjectId] = useState(0);
    const [userId, setUserId] = useState(0);
    const [checkId, setCheckId] = useState(0)
    const [checkData, setCheckData] = useState([])
    const [roleId, setRoleId] = useState(0);
    const [projectData, setProjectData] = useState([])
    const [userData, setUserData] = useState([])
    const [roleData, setRoleData] = useState([])
    const [loading, setLoading] = useState(true)
    const [hide, setHide] = useState(false)
    const [toastData, setToastData] = useState({
        Message: "",
        Status: ""
    });
    const [allBtn, setAllBtn] = useState({
        MapUserBtn: false,
        DeleteUser: false,
        MapCheck: false,
        DeleteCheck: false
    })

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
    }

    useEffect(() => {
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${GetProjectList}1`, { headers: headers })
            .then((res) => {
                setLoading(false)
                setProjectData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)
            })
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${GetCheckNameList}`, { headers: headers })
            .then((res) => {
                setCheckData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)
            })
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${UserDetailsByProjectId}0`, { headers: headers })
            .then((res) => {
                setUserData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)
            })
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${Roles}0`, { headers: headers })
            .then((res) => {
                setRoleData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)
            })
        setLoading(false)
    }, [])

    function mapUser() {
        setAllBtn(data => ({
            ...data,
            MapUserBtn: true
        }))
        var payload = {
            UserId: userId,
            ProjectId: projectId,
            RoleId: roleId
        }
        axios.post(`${sessionStorage.getItem('BASE_ENDPOINT')}${MapUserToProject}`, payload, { headers: headers })
            .then((res) => {
                setAllBtn(data => ({
                    ...data,
                    MapUserBtn: false
                }))
                setToastData(data => ({
                    ...data,
                    Message: res.data.Message,
                    Status: "Green"
                }))
                setHide(true)
            })
            .catch((err) => {
                setAllBtn(data => ({
                    ...data,
                    MapUserBtn: false
                }))
                setToastData(data => ({
                    ...data,
                    Message: 'Something went wrong',
                    Status: "Red"
                }))
                setHide(true)
            })
        setTimeout(() => {
            setHide(false)
        }, 3000)
    }
    function DeleteUserMapping() {
        setAllBtn(data => ({
            ...data,
            DeleteUser: true
        }))
        axios.delete(`${sessionStorage.getItem('BASE_ENDPOINT')}${DeleteUserProjectRoleMapping}${projectId}&userId=${userId}`
            , { headers: headers })
            .then(res => {
                setAllBtn(data => ({
                    ...data,
                    DeleteUser: false
                }))
                setToastData(data => ({
                    ...data,
                    Message: res.data.Message,
                    Status: "Green"
                }))
                setHide(true)
            })
            .catch(err => {
                setAllBtn(data => ({
                    ...data,
                    DeleteUser: false
                }))
                setToastData(data => ({
                    ...data,
                    Message: 'Something went wrong',
                    Status: "Red"
                }))
                setHide(true)
            })
        setTimeout(() => {
            setHide(false)
        }, 3000)
    }
    function MapCheck() {
        setAllBtn(data => ({
            ...data,
            MapCheck: true
        }))
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${InsertProjectCheckMapping}${projectId}&checkId=${checkId}`
            , { headers: headers })
            .then(res => {
                setAllBtn(data => ({
                    ...data,
                    MapCheck: false
                }))
                setToastData(data => ({
                    ...data,
                    Message: res.data.Message,
                    Status: "Green"
                }))
                setHide(true)
            })
            .catch(err => {
                setAllBtn(data => ({
                    ...data,
                    MapCheck: false
                }))
                setToastData(data => ({
                    ...data,
                    Message: 'Something went wrong',
                    Status: "Red"
                }))
                setHide(true)
            })
        setTimeout(() => {
            setHide(false)
        }, 3000)
    }
    function DeleteCheck() {
        setAllBtn(data => ({
            ...data,
            DeleteCheck: true
        }))
        axios.delete(`${sessionStorage.getItem('BASE_ENDPOINT')}${DeleteProjectCheckMapping}${projectId}&checkId=${checkId}`
            , { headers: headers })
            .then(res => {
                setAllBtn(data => ({
                    ...data,
                    DeleteCheck: false
                }))
                setToastData(data => ({
                    ...data,
                    Message: res.data.Message,
                    Status: "Green"
                }))
                setHide(true)
            })
            .catch(err => {
                setAllBtn(data => ({
                    ...data,
                    DeleteCheck: false
                }))
                setToastData(data => ({
                    ...data,
                    Message: 'Something went wrong',
                    Status: "Red"
                }))
                setHide(true)
            })
        setTimeout(() => {
            setHide(false)
        }, 3000)
    }

    return (
        loading ? <Spinner />
            :
            <Fragment>
                <div className='row'>
                    <div className={'col-sm-3'}>
                        <Select
                            value={checkId}
                            onChange={(e) => setCheckId(e.target.value)}
                            sx={{ width: '300px' }}
                            labelId="Check Name"
                            id="check-name"
                        >
                            <MenuItem value={0}>Select Check</MenuItem>
                            {
                                checkData.map((x, index) => <MenuItem key={index} value={x.Id}>{x.CheckName}</MenuItem>)
                            }
                        </Select>
                    </div>
                    <div className='col-sm-3'>
                        <Select
                            sx={{ width: '300px' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={userId}
                            onChange={e => setUserId(e.target.value)}
                        >
                            <MenuItem value={0}>Select User</MenuItem>
                            {
                                userData.map((x, index) => <MenuItem key={index} value={x.Id}>{x.UserName} ({x.Id})</MenuItem>)
                            }
                        </Select>
                    </div>
                    <div className='col-sm-3'>
                        <Select
                            sx={{ width: '300px' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={projectId}
                            onChange={e => setProjectId(e.target.value)}
                        >
                            <MenuItem value={0}>Select Project</MenuItem>
                            {
                                projectData.map((x, index) => <MenuItem key={index} value={x.ProjectId}>{x.ProjectName} ({x.ProjectId})</MenuItem>)
                            }
                        </Select>
                    </div>
                    <div className='col-sm-3'>
                        <Select
                            sx={{ width: '300px' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={roleId}
                            onChange={e => setRoleId(e.target.value)}
                        >
                            <MenuItem value={0}>Select Role</MenuItem>
                            {
                                roleData.map((x, index) => <MenuItem key={index} value={x.Id}>{x.RoleName}</MenuItem>)
                            }
                        </Select>
                    </div>
                </div>
                <div className={'row'} style={{ marginTop: '25px' }}>
                    <Alert
                        severity="info">Select User,Project and Role and hit the button to map user into the project.
                        <LoadingButton
                            onClick={mapUser}
                            sx={{ marginLeft: '10px' }}
                            loadingPosition="center"
                            variant="outlined"
                            loading={allBtn.MapUserBtn}
                            disabled={!userId || !projectId || !roleId}
                        >
                            MapUser
                        </LoadingButton>
                    </Alert>
                </div>
                <div className={'row'} style={{ marginTop: '10px' }}>
                    <Alert
                        severity="info">
                        Select User ,Project and hit the button to delete the user from that project.
                        <LoadingButton
                            sx={{ marginLeft: '10px' }}
                            loadingPosition="center"
                            variant="outlined"
                            onClick={DeleteUserMapping}
                            disabled={!userId || !projectId}
                            loading={allBtn.DeleteUser}
                        >
                            DeleteUser
                        </LoadingButton>

                    </Alert>
                </div>
                <div className={'row'} style={{ marginTop: '10px' }}>
                    <Alert
                        severity="info">
                        Select Check,Project and hit the button to delete the check from that project.
                        <LoadingButton
                            sx={{ marginLeft: '10px' }}
                            loadingPosition="center"
                            variant="outlined"
                            onClick={DeleteCheck}
                            loading={allBtn.DeleteCheck}
                            disabled={!checkId || !projectId}
                        >
                            DeleteCheck
                        </LoadingButton>
                    </Alert>
                </div>
                <div className={'row'} style={{ marginTop: '10px' }}>
                    <Alert
                        severity="info">
                        Select Check,Project and hit the button to insert the check into the project.
                        <LoadingButton
                            sx={{ marginLeft: '10px' }}
                            loadingPosition="center"
                            variant="outlined"
                            onClick={MapCheck}
                            loading={allBtn.MapCheck}
                            disabled={!checkId || !projectId}
                        >
                            MapCheck
                        </LoadingButton>
                    </Alert>
                </div>
                <div className='row'>
                    <Toast Message={toastData.Message} Show={hide} Status={toastData.Status} />
                </div>
            </Fragment>
    )
}