import {Fragment, useEffect, useMemo, useState} from "react";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import Spinner from "../Loaders/Spinner";
import {AgGridReact} from "ag-grid-react";
import axios from "axios";
import {DeleteDataByCaseId, GetChecksData} from "../EndPoint";
import Button from "@mui/material/Button";
import CustomDialog from "../Dialog/CustomDialog";
import Alert from "@mui/material/Alert";

export default function Case() {
    const [caseId,setCaseId] = useState(null)
    const [loading,setLoading] = useState(true);
    const [btnLoading,setBtnLoading] = useState(false)
    const [deletebtnLoading,setDeleteBtnLoading] = useState(false)
    const [caseData,setCaseData] = useState([]);
    const [postCaseData,setPostCaseData] = useState([])
    const [dialogData,setDialogData] = useState({
        Show : false,
        Json : ''
    })
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
    }

    function closeDialog()
    {
        setDialogData(data => ({
            ...data,
            Show: false,
            Json: ''
        }))
    }

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            editable : true
        };
    }, []);
    const [columnDefs] = useState([
        { field: 'CaseId', headerName: 'CaseId', headerClass: 'text-center' },
        {field: 'ListingId',headerName: 'ListingId',headerClass: 'text-center'},
        {field: 'CheckName',headerName: 'CheckName',headerClass: 'text-center'},
        {field: 'Stage',headerName: 'Stage',headerClass: 'text-center'},
        {field: 'Type',headerName: 'Type',headerClass: 'text-center'},
        {field: 'Message',headerName: 'Message',headerClass: 'text-center'},
        {field: 'APIResponseStatus',headerName: 'APIResponseStatus',headerClass: 'text-center'},
        {field: 'RequestJson',headerName: 'Payload',headerClass: 'text-center'},
        {field: 'match_status',headerName: 'Status',headerClass: 'text-center'},
        {field: 'match_score',headerName: 'Score',headerClass: 'text-center'},
        {
            headerName: "View Json", headerClass: 'text-center',
            cellRenderer: (params) =>
            {
                return (
                    <div>
                        <Button
                            variant="contained"
                            onClick={() => showJson(params.data.Json)}
                        >
                            Json
                        </Button>
                    </div>
                )
            }
        }
    ])

    function showJson(jsonData)
    {
        setDialogData(data => ({
            ...data,
            Show: true,
            Json:jsonData
        }))
    }
    function searchCase()
    {
        setBtnLoading(true)
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${GetChecksData}${caseId}`, { headers: headers })
            .then((res) => {
                setCaseData(res.data.Body.preCheckResult)
                setPostCaseData(res.data.Body.postCheckResult)
                setLoading(false)
                setBtnLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setBtnLoading(false)
            })
    }
    function deleteCase()
    {
        setLoading(true)
        setDeleteBtnLoading(true)
        axios.delete(`${sessionStorage.getItem('BASE_ENDPOINT')}${DeleteDataByCaseId}${caseId}`, { headers: headers })
            .then((res) => {
                setCaseData('')
                setDeleteBtnLoading(false)
            })
            .catch((err) => {
                setDeleteBtnLoading(false)
                console.log(err)
            })
    }

    return(
        <Fragment>
            <div className='row'>
                <div className='col-sm-4'>
                    <TextField
                        fullWidth
                        label="Enter Case Id"
                        variant="standard"
                        color="secondary"
                        onChange={e => setCaseId(e.target.value)}
                    />
                </div>
                <div className='col-sm-1'>
                    <LoadingButton
                        onClick={searchCase}
                        sx = {{marginTop:'12px'}}
                        loadingPosition="center"
                        variant="outlined"
                        loading ={btnLoading}
                        disabled={caseId === null || caseId === ''}
                    >
                        Search
                    </LoadingButton>
                </div>
                <div className='col-sm-2'>
                    <LoadingButton
                        onClick={deleteCase}
                        sx = {{marginTop:'12px'}}
                        loadingPosition="center"
                        variant="outlined"
                        disabled={caseId === null || caseId === ''}
                        loading ={deletebtnLoading}
                    >
                        Delete
                    </LoadingButton>
                </div>
            </div>
            <Divider sx={{marginTop:'10px'}}/>
            <div className='row' style={{marginTop:'10px'}}>
                {
                    (loading || (caseId === null || caseId === '' ))  ? <Spinner/> :
                        <div className={'container'}>
                            <div className='row'>
                                {
                                    btnLoading || loading ? <Spinner/> :
                                        <div className="ag-theme-alpine" style={{ height: 200,width:'100%' }}>
                                            <AgGridReact
                                                animateRows={true}
                                                rowHeight={50}
                                                defaultColDef={defaultColDef}
                                                rowData={caseData}
                                                columnDefs={columnDefs}>
                                            </AgGridReact>
                                        </div>
                                }
                            </div>
                            <div className='row' style={{marginTop:'10px'}}>
                                <Alert severity="success">Post Check Response</Alert>
                            </div>
                            <div className='row' style={{marginTop:'10px'}}>
                                {
                                    btnLoading || loading ? <Spinner/> :
                                        <div className="ag-theme-alpine" style={{ height: 200,width:'100%' }}>
                                            <AgGridReact
                                                animateRows={true}
                                                rowHeight={50}
                                                defaultColDef={defaultColDef}
                                                rowData={postCaseData}
                                                columnDefs={columnDefs}>
                                            </AgGridReact>
                                        </div>
                                }
                            </div>
                        </div>
                }
            </div>
            <div className='row'>
                <CustomDialog Dialog={closeDialog} Show = {dialogData.Show} Data = {dialogData.Json}/>
            </div>
        </Fragment>
    )
}