import {Fragment, useEffect, useMemo, useState} from "react";
import axios from "axios";
import {
    GetCheckNameList,
    GetListByCheckName,
    DeleteParticularList,
    DeleteAllListByCheckName,
    GetProjectList
} from "../EndPoint";
import Spinner from "../Loaders/Spinner";
import {MenuItem, Select} from "@mui/material";
import * as React from "react";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import {AgGridReact} from "ag-grid-react";
import Toast from "../Toaster/Toast";
import Fab from '@mui/material/Fab';

export default function ProjectListMapping()
{
    const [projectId, setProjectId] = useState(0);
    const [checkName, setCheckName] = useState(0)
    const [checkData, setCheckData] = useState([])
    const [projectData, setProjectData] = useState([])
    const [listData,setListData] = useState([])
    const [loading, setLoading] = useState(true)
    const [listIds,setListIds] = useState([])
    const [delBtnLoading,setDelBtnLoading] = useState(false)
    const [allBtnLoading,setAllBtnLoading] = useState(false)
    const [reload ,setReload] = useState(false)
    const [toastData, setToastData] = useState({
        Message: "",
        Status: ""
    });
    const [selectedCount,setSelectedCount] = useState(0)
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
    }
    useEffect(() =>
    {
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${GetProjectList}1`, { headers: headers })
            .then((res) => {
                setProjectData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)
            })
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${GetCheckNameList}`, { headers: headers })
            .then((res) => {
                setCheckData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)
            })
    },[])
    useEffect(() => {
        setLoading(true)
        if(checkName !== 0)
        {
            axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${GetListByCheckName}?checkName=${checkName}&projectId=${projectId}`, { headers: headers })
                .then((res) => {
                    if(res.data.Body === null)
                    {
                        setLoading(false)
                        setListData([])
                        setSelectedCount(0)
                    }
                    else {
                        setLoading(false)
                        setListData(res.data.Body)
                        setSelectedCount(0)
                    }

                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },[checkName,reload])

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            editable : true
        };
    }, []);

    const handleRowChange = async (ListId) => {
        var count = selectedCount;
        if(listIds.includes(ListId))
        {
            if(count > 0)
            {
                count = count - 1
            }
            setSelectedCount(count);
            await setListIds(listIds.filter(item => item !== ListId));
        }
        else {
            setSelectedCount(count + 1);
            await setListIds(item => [...item, ListId]);
        }
    }

    function deleteListMapping()
    {
        if(listIds.length === 0)
        {
            setToastData(data => ({
                Message: "Please select List First",
                Show: true,
                Status: "Red"
            }))
        }
        else {
            setDelBtnLoading(true)
            const payload = {
                "checkName":checkName,
                "listIds":listIds,
                "projectId":projectId
            }
            axios.post(`${sessionStorage.getItem('BASE_ENDPOINT')}${DeleteParticularList}`,payload,{headers:headers})
                .then((res) =>
                {
                    if(res.status === 200)
                    {
                        setToastData(data => ({
                            Message: "List Deleted Successfully",
                            Show: true,
                            Status: "Green"
                        }))
                        setDelBtnLoading(false)
                        setReload(true)
                        setSelectedCount(0)
                    }
                })
                .catch(err =>
                {
                    setDelBtnLoading(false)
                    setToastData(data => ({
                        Message: "No Data Found",
                        Show: true,
                        Status: "Red"
                    }))
                })
        }
        setTimeout(() => {
            setToastData(data => ({
                Show: false,
            }))
        },3000)
    }
    function deleteAllListMapping()
    {
        setAllBtnLoading(true)
        axios.delete(`${sessionStorage.getItem('BASE_ENDPOINT')}${DeleteAllListByCheckName}?checkName=${checkName}&projectId=${projectId}`,{headers:headers})
            .then((res) =>
            {
                if(res.status === 200)
                {
                    setToastData(data => ({
                        Message: "List Deleted Successfully",
                        Show: true,
                        Status: "Green"
                    }))
                    setAllBtnLoading(false)
                    setReload(true)
                    setSelectedCount(0)
                }
            })
            .catch(err =>
            {
                setAllBtnLoading(false)
                setToastData(data => ({
                    Message: "No Data Found",
                    Show: true,
                    Status: "Red"
                }))
            })
        setTimeout(() => {
            setToastData(data => ({
                Show: false,
            }))
        },3000)
    }

    const [columnDefs] = useState([
        {field: 'ListId',headerName: 'List Id',width: 250, maxWidth: 250 },
        {field: 'ListName',headerName: 'List Name',headerClass: 'text-center'},
        {field: 'ListUId',headerName: 'List UID',headerClass: 'text-center'},
        {field: 'TagName',headerName: 'Authority Name',headerClass: 'text-center'},
        {field: 'CheckName',headerName: 'Check Name',headerClass: 'text-center',width: 200, maxWidth: 200},
        {field: 'IsChecked',headerName: 'Active',headerClass: 'text-center',width: 150, maxWidth: 150},
    ])

    return (
        <Fragment>
            <div className='row'>
                <div className='col-sm-3'>
                    <Select
                        sx={{ width: '300px' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={projectId}
                        onChange={e => setProjectId(e.target.value)}
                    >
                        <MenuItem value={0}>Select Project</MenuItem>
                        {
                            projectData.map((x, index) => <MenuItem key={index} value={x.ProjectId}>{x.ProjectName} ({x.ProjectId})</MenuItem>)
                        }
                    </Select>
                </div>
                <div className={'col-sm-3'}>
                    <Select
                        value={checkName}
                        onChange={(e) => setCheckName(e.target.value)}
                        sx={{ width: '300px' }}
                        labelId="Check Name"
                        id="check-name"
                        disabled={!projectId}
                    >
                        <MenuItem value={0}>Select Check</MenuItem>
                        {
                            checkData.map((x, index) => <MenuItem key={index} value={x.CheckName}>{x.CheckName}</MenuItem>)
                        }
                    </Select>
                </div>
                <div className='col-sm-2'>
                    <LoadingButton
                        sx={{ marginTop: '20px' }}
                        loadingPosition="center"
                        variant="contained"
                        onClick={deleteListMapping}
                        disabled={!checkName || !projectId}
                        loading={delBtnLoading}
                    >
                        Delete Selected Mapping
                    </LoadingButton>
                </div>
                <div className='col-sm-2'>
                    <LoadingButton
                        sx={{ marginTop: '20px' }}
                        loadingPosition="center"
                        variant="contained"
                        onClick={deleteAllListMapping}
                        disabled={!checkName || !projectId}
                        loading={allBtnLoading}
                    >
                        Delete All Mapping
                    </LoadingButton>
                </div>
            </div>
            <Divider sx={{marginTop:'10px'}}/>
            <div className='container-fluid'>
                <div className='row' style={{marginTop:'15px'}}>
                    {
                        loading ? <Spinner/>
                            :
                            <div className="ag-theme-alpine" style={{ height: 600,width:'100%' }}>
                                <AgGridReact
                                    animateRows={true}
                                    rowHeight={50}
                                    defaultColDef={defaultColDef}
                                    rowData={listData}
                                    rowSelection={'multiple'}
                                    rowMultiSelectWithClick = {true}
                                    onRowSelected={(e) => {handleRowChange(e.data.ListId)}}
                                    columnDefs={columnDefs}>
                                </AgGridReact>
                            </div>
                    }
                </div>
                <div className={'row'}>
                    <Toast Message = {toastData.Message} Show={toastData.Show} Status = {toastData.Status}/>
                </div>
                <Fab color="primary" aria-label="add" sx={{position:'absolute',bottom: 56, right: 16,}}>
                    {selectedCount}
                </Fab>
            </div>
        </Fragment>
    )
}