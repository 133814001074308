import React, { Fragment } from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios';
import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import { GetRunningQueueConfiguration, GetWorkerConfiguration, UpdateWorkerStatus } from '../EndPoint';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LoadingButton from "@mui/lab/LoadingButton";
import Toast from "../Toaster/Toast";

function Worker() {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
    }

    const navigate = useNavigate();
    useEffect(() => {
        if (sessionStorage.getItem('token') === null) {
            navigate('/')
        }
    }, [])

    const [queueData, setQueueData] = useState({})
    const [workerData, setWorkerData] = useState([])
    const [loading, setLoading] = useState(false)
    const [toastData, setToastData] = useState({
        Message: "",
        Status: ""
    });

    function queueConfigurationData(){
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${GetRunningQueueConfiguration}`, { headers: headers })
            .then((res) => {
                setQueueData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    function workerConfigurationData()
    {
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${GetWorkerConfiguration}`, { headers: headers })
            .then((res) => {
                setWorkerData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        queueConfigurationData()
        setInterval(() => {
            queueConfigurationData()
        },5000)
    }, [])
    useEffect(() => {
        workerConfigurationData();
        setInterval(() => {
            workerConfigurationData();
        },5000)
    }, [])

    function updateWorkerStatus(event) {
        console.log(event.target.value)
        setLoading(true)
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${UpdateWorkerStatus}?workerId=${event.target.value}`, { headers: headers })
            .then((res) => {
                setLoading(false)
                setToastData(data => ({
                    Message: "Status Updated Successfully",
                    Show: true,
                    Status: "Green"
                }))
            })
            .catch((err) => {
                setLoading(false)
                setToastData(data => ({
                    Message: "Something went wrong",
                    Show: true,
                    Status: "Red"
                }))
            })
        setTimeout(() => {
            setToastData(data => ({
                Show: false,
            }))
        }, 3000)
    }

    return (
        <Fragment>
            <div className="container">
                <div className="row">
                    <h2>Queue Configuration</h2>
                </div>

                <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col-sm-4">
                        <span>Max Item :- {queueData.MaxItems}</span>
                    </div>
                    <div className="col-sm-4">
                        <span>QueuePickCount :- {queueData.QueuePickCount}</span>
                    </div>
                    <div className="col-sm-4">
                        <span>UnderProcess:- {queueData.UnderProcess}</span>
                    </div>
                </div>
            </div>

            <Divider sx={{ marginTop: '10px' }} />

            <div className="container" style={{ marginTop: '20px' }}>
                <div className="row">
                    <h2>Worker Configuration</h2>
                </div>

                <div className="row" style={{ marginTop: '10px' }}>

                    {
                        workerData.map((item, index) =>
                            <div className='col-sm-6 col-lg-3 mb-3 mb-lg-5' key={index}>
                                <Card sx={{ borderRadius: '8px' }}>
                                    <CardContent>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            <b>{item.WorkerName} - {item.Id}</b>
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            <b>In Use :- {item.InUse == true ? 'Yes' : 'No'}</b>
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            <b>Lock Status :- {item.IsLockedStatus}</b>
                                        </Typography>
                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            <b>Demo Worker :- {item.IsDemoWorker ==  true ? 'Yes' : 'No'}</b>
                                        </Typography>
                                    </CardContent>
                                    <div className='row' style={{ marginBottom: '20px' }}>
                                        <div className='col-sm-6' style={{ marginLeft: '80px' }}>
                                            <LoadingButton
                                                loadingPosition="center"
                                                loading={loading}
                                                title="Update Worker Status"
                                                variant="contained"
                                                size="small"
                                                color='primary'
                                                disabled={item.InUse == false}
                                                value={item.Id}
                                                onClick={e => updateWorkerStatus(e)}>
                                                Change Status
                                            </LoadingButton>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        )
                    }
                </div>
                <div className={'row'}>
                    <Toast Message={toastData.Message} Show={toastData.Show} Status={toastData.Status} />
                </div>
            </div>

        </Fragment>
    )
}

export default Worker