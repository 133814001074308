import { Fragment, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { ListingHistoryWithChecks, DeleteDataByBatchId, GetProjectList} from "../EndPoint";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import * as React from "react";
import { MenuItem, Select } from "@mui/material";
import Divider from "@mui/material/Divider";
import Spinner from "../Loaders/Spinner";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../Dialog/CustomDialog";


export default function BatchData() {
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    //const [settingBtn,setSettingBtn] = useState(false);
    const [projectData, setProjectData] = useState([]);
    const [projectId, setProjectId] = useState(0);
    const [batch, setBatchData] = useState([]);
    const [dialogData, setDialogData] = useState({
        Show: false,
        Json: ''
    })

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
    }

    const navigate = useNavigate();
    useEffect(() => {
        if (sessionStorage.getItem('token') === null) {
            navigate('/')
        }
    }, [])

    function closeDialog() {
        setDialogData(data => ({
            ...data,
            Show: false,
            Json: ''
        }))
    }
    function showJson(jsonData) {
        setDialogData(data => ({
            ...data,
            Show: true,
            Json: jsonData
        }))
    }

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
        };
    }, []);

    useEffect(() => {
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${GetProjectList}1`, { headers: headers })
            .then((res) => {
                setLoading(false)
                setProjectData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (projectId !== 0) {
            sessionStorage.setItem("projectId", projectId)
            setLoading(true)
            axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${ListingHistoryWithChecks}${projectId}`, { headers: headers })
                .then((res) => {
                    setLoading(false)
                    setBatchData(res.data.Body.listingData)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [projectId])

    function DeleteByBatchId(batchId) {
        setBtnLoading(true)
        axios.delete(`${sessionStorage.getItem('BASE_ENDPOINT')}${DeleteDataByBatchId}${batchId}`, { headers: headers })
            .then((res) => {
                setBtnLoading(false);
                setLoading(true)
                axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${ListingHistoryWithChecks}${sessionStorage.getItem("projectId")}`, { headers: headers })
                    .then((res) => {
                        setLoading(false)
                        setBatchData(res.data.Body.listingData)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const [columnDefs] = useState([
        { field: 'BatchId', headerName: 'Batch Name', headerClass: 'text-center' },
        { field: 'ProjectName', headerName: 'Project Name', headerClass: 'text-center' },
        { field: 'UserName', headerName: 'User Name', headerClass: 'text-center' },
        { field: 'CreatedOn', headerName: 'CreatedOn', headerClass: 'text-center' },
        { field: 'ScanStatusToDisplay', headerName: 'Processing Status', headerClass: 'text-center' },
        {
            headerName: "Action", headerClass: 'text-center',
            cellRenderer: (params) => {
                return (
                    <div>
                        <LoadingButton
                            loadingPosition="center"
                            variant="outlined"
                            onClick={() => DeleteByBatchId(params.data.BatchId)}
                            loading={btnLoading}
                        >
                            Delete
                        </LoadingButton>
                    </div>
                )
            }
        },
        {
            headerName: "Setting", headerClass: 'text-center',
            cellRenderer: (params) => {
                return (
                    <div>
                        <Button
                            loadingPosition="center"
                            variant="outlined"
                            onClick={() => showJson(params.data.BatchSetting.entityInputCheckModels)}
                        >
                            Show Settings.
                        </Button>
                    </div>

                )
            }
        }
    ])

    return (
        <Fragment>
            <div className='row'>
                <div className='col-sm-4'>
                    <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={projectId}
                        onChange={e => setProjectId(e.target.value)}
                    >
                        <MenuItem value={0}>Select Project</MenuItem>
                        {
                            projectData.map((x, index) => <MenuItem key={index} value={x.ProjectId}>{x.ProjectName} - {x.ProjectId}</MenuItem>)
                        }
                    </Select>
                </div>
            </div>
            <Divider sx={{ marginTop: '10px' }} />
            <div className='row' style={{ marginTop: '10px' }}>
                {
                    loading ? <Spinner />
                        :
                        <div className="ag-theme-alpine" style={{ height: 700, width: '100%' }}>
                            <AgGridReact
                                animateRows={true}
                                rowHeight={50}
                                defaultColDef={defaultColDef}
                                rowData={batch}
                                columnDefs={columnDefs}>
                            </AgGridReact>
                        </div>
                }
            </div>
            <div className='row'>
                <CustomDialog Dialog={closeDialog} Show={dialogData.Show} Data={dialogData.Json} />
            </div>
        </Fragment>
    )
}