import {Fragment, useState} from "react";
import TextField from "@mui/material/TextField";
import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {MenuItem, Select} from "@mui/material";
import axios from "axios";
import Toast from "../Toaster/Toast";

export default function ApiMapping() {
    const [btn,setBtnLoading] = useState(false)
    const [mappingEndPoint,setMappingEndPoint] = useState('Select Mapping Type')
    const [toast,setToastData] = useState({
        Show :false,
        Message:'',
        Status:''
    })
    const [Mapping,setMapping] = useState({
        CheckName:'',
        tag:'',
        title:''
    })
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('DATAFACADE_TOKEN')
    }
    function handleSubmit()
    {
        setBtnLoading(true)
        var tag = Mapping.tag.split(',');
        var title = Mapping.title.split(',')
        if(tag.length === title.length)
        {
            const payload = {
                CheckName : Mapping.CheckName,
                ApiTags : Mapping.tag.split(','),
                FinalTags : Mapping.title.split(',')
            }
            axios.post(`${sessionStorage.getItem('DATA_FACADE')}${mappingEndPoint}`,payload,{headers:headers})
                .then((res) =>
                {
                    setBtnLoading(false)
                    setToastData(data => ({
                        ...data,
                        Show: true,
                        Message: res.data,
                        Status: 'Green'
                    }))
                })
                .catch((err) =>
                {
                    setBtnLoading(false)
                    setToastData(data => ({
                        ...data,
                        Show: true,
                        Message: "Something went wrong",
                        Status: 'Red'
                    }))
                })
        }
        else {
            setBtnLoading(false)
            setToastData(data => ({
                ...data,
                Show: true,
                Message: "Length of all the fields should be equal or Select Mapping Type",
                Status: 'Red'
            }))
        }
        setTimeout(() =>
        {
            setToastData(data => ({
                ...data,
                Show: false
            }))
        },3000)

    }

    return (
        <Fragment>
            <div className='row'>
                <div className='col-sm-3'>
                    <Select
                        value={mappingEndPoint}
                        onChange={(e) => setMappingEndPoint(e.target.value)}
                        sx={{width:'300px'}}
                        labelId="Check Name"
                        id="check-name"
                    >
                        <MenuItem value={'Select Mapping Type'}>Select Mapping Type</MenuItem>
                        <MenuItem value={'/InsertApiMapping'}>Api Mapping</MenuItem>
                        <MenuItem value={'/InsertAdditionalInfoMapping'}>Additonal Info Mapping</MenuItem>
                    </Select>
                </div>
            </div>
            <div className='row' style={{marginTop:'10px'}}>
                <div className='col-sm-6'>
                    <TextField
                        fullWidth
                        label="Enter Check Name"
                        variant="standard"
                        value={Mapping.CheckName}
                        onChange={e => setMapping(data => ({
                            ...Mapping,
                            CheckName: e.target.value
                        }))}
                        color="secondary"
                    />
                </div>
                <div className='col-sm-6'>
                    <TextField
                        fullWidth
                        label="Enter Comma Separated Api Tag Name"
                        variant="standard"
                        color="secondary"
                        value={Mapping.tag}
                        onChange={e => setMapping(data => ({
                            ...Mapping,
                            tag: e.target.value
                        }))}
                    />
                </div>
            </div>
            <div className='row' style={{marginTop:'10px'}}>
                <div className='col-sm-6'>
                    <TextField
                        fullWidth
                        label="Enter Comma Separated Api Title Name"
                        variant="standard"
                        color="secondary"
                        value={Mapping.title}
                        onChange={e => setMapping(data => ({
                            ...Mapping,
                            title: e.target.value
                        }))}
                    />
                </div>
            </div>
            <div className='row' style={{marginTop:'10px'}}>
                <div className='col-sm-1'>
                    <LoadingButton
                        sx = {{marginTop:'12px'}}
                        loadingPosition="center"
                        variant="contained"
                        loading ={btn}
                        disabled={mappingEndPoint === 'Select Mapping Type'}
                        onClick={handleSubmit}
                    >
                        Submit
                    </LoadingButton>
                </div>
            </div>
            <div className='row'>
                <Toast Message = {toast.Message} Show = {toast.Show} Status = {toast.Status} />
            </div>
        </Fragment>
    )
}