import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

export default function CardSkeleton() {
    return (
        <div className='col-sm-3'>
            <Card>
                <CardContent>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={250} height={60} />
                        <Skeleton variant="rectangular" width={250} height={60} />
                        <Skeleton variant="rounded" width={150} height={30} />
                    </Stack>
                </CardContent>
            </Card>
        </div>
    )
}