import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProjectDetails from "./ProjectDetails";
import Users from "./Users";
import MapUser from "./MapUser";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Project() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const navigate = useNavigate();
  useEffect(() =>
  {
    if(sessionStorage.getItem('token') === null)
    {
      navigate('/')
    }
  },[])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper'}}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Project Details" {...a11yProps(0)} />
          <Tab label="Users" {...a11yProps(1)} />
          <Tab label="Manage" {...a11yProps(2)} />
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
          <ProjectDetails/>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Users/>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <MapUser/>
        </TabPanel>
    </Box>
  );
}