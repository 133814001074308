import React, {Fragment, useEffect, useState} from 'react'
import axios from 'axios'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {DeleteDataByProjectId, ProjectDetailsByUserId, ProjectSetting} from '../EndPoint'
import ProjectCard from "./ProjectCard";
import CardSkeleton from "./CardSkeleton";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Toast from "../Toaster/Toast";
import CustomDialog from "../Dialog/CustomDialog";

function ProjectDetails() {
    const [projectData, setProjectData] = useState([])
    const [loading,setLoading] = useState(true)
    const [snack,setSnackBar] = useState(false);
    const [message,setMessage] = useState('');
    const [projectId,setProjectId] = useState(0)
    const [btnLoading,setBtnLoading] = useState({
        delete:false,
        setting:false
    })
    const [dialogData,setDialogData] = useState({
        Show : false,
        Json : ''
    })
    const [toastStatus,setToastStatus] = useState('')
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
    }

    function checkValidation()
    {
        setOpen(false)
        setBtnLoading(data => ({
            ...data,
            delete: true
        }))
        axios.delete(`${sessionStorage.getItem('BASE_ENDPOINT')}${DeleteDataByProjectId}${projectId}`, { headers: headers })
            .then((res) => {
                setBtnLoading(data => ({
                    ...data,
                    delete: false
                }))
                setSnackBar(true)
                setMessage(res.data.Message);
                setToastStatus('Green')
            })
            .catch((err) => {
                setSnackBar(true)
                setMessage("Something went wrong");
                setToastStatus('Error')
            })
        setTimeout(() =>
        {
            setSnackBar(false);
        },3000)
    }

    useEffect(() => {
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${ProjectDetailsByUserId}1`, { headers: headers })
            .then((res) => {
                setLoading(false)
                setProjectData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    function closeDialog()
    {
        setDialogData(data => ({
            ...data,
            Show: false,
            Json: ''
        }))
    }
    function showJson(projectId)
    {
        setBtnLoading(data => ({
            ...data,
            setting: true
        }))
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${ProjectSetting}${projectId}`, { headers: headers })
            .then((res) => {
                setBtnLoading(data => ({
                    ...data,
                    setting: false
                }))
                setDialogData(data => ({
                    ...data,
                    Show: true,
                    Json: res.data.Body
                }))
            })
            .catch((err) => {
                setSnackBar(true)
                setMessage("Something went wrong");
                setToastStatus('Error')
                setTimeout(() =>
                {
                    setSnackBar(false);
                },3000)
            })
    }
    function DeleteProjectId(projectId)
    {
        setProjectId(projectId)
        setOpen(true)
    }
    return (

            loading ?
                <div className='row'>
                    <CardSkeleton/>
                    <CardSkeleton/>
                    <CardSkeleton/>
                    <CardSkeleton/>
                </div>
                :
                <Fragment>
                    <div className='row'>
                        {
                            projectData.map((x,index) => <ProjectCard Delete={btnLoading.delete} Setting={btnLoading.setting} key={index} OpenSetting = {showJson} DeleteFunction={DeleteProjectId} ProjectImage = {x.ProjectImage} ProjectName = {x.ProjectName}
                                                                      ProjectId = {x.Id}/>)
                        }
                        <Toast Message = {message} Show={snack} Status = {toastStatus}/>
                    </div>
                    <div className='row'>
                        <CustomDialog Dialog={closeDialog} Show = {dialogData.Show} Data = {dialogData.Json}/>
                    </div>
                    <div className='row'>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"are you sure you want to delete it?"}
                            </DialogTitle>
                            <DialogActions>
                                <Button variant={"outlined"} onClick={handleClose}>No</Button>
                                <Button variant={"outlined"} onClick={checkValidation} autoFocus>
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Fragment>
    )
}

export default ProjectDetails