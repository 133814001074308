import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Home/Home';
import SignIn from './SignIn/SignIn';
import { useEffect, useState } from 'react';
import axios from 'axios';

function App() {

  const [token,setToken] = useState('');
  useEffect(() => {
    var headers = {
      'Authorization': 'Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGVuYyNyc2Etb2FlcCIsImVuYyI6IkExMjhDQkMtSFMyNTYiLCJraWQiOiI1OEYxNzY3QzMxRDQ3Q0VCOTdDMTk0MDBBMTIyNEYwNUI5MUFEMDRFIiwidHlwIjoiSldUIiwiY3R5IjoiSldUIn0.hW4XZ-7r5J-b9P3RTtdyn2ejL8eRFcvQT1Mx5NPCnsKEiNz3C_5ZIR8xRyQ4bJJC3piT_BWajuaH28s5wuwsGlDx31vTbv3pmWG96fWrjj3CBJXdiw18yuVz2SFcC0jXFvuo6_uF_6c-YHKZDHBGOg-e98fvYuB_7s9eipfFBPGtHHLawFvaoS9wgJxSml6eBiHs2ppv0pTr4B2LYLUkRMiyh47KfO29s_lDkrRlo8WfysEJyyNAetGiM5zHCsIYaU5U3uLPHsNGVkifd3rGVRph0VnEOW723-GYIJ8hYi5oNYhcLtEjCWHVYhhw6kaxngMplvMCzXp-5gXxQtm1zg.dauZ3LwnIcXVzwGhSYs_2g.-wrIjWzu889-GatN-1Kl7mqVxOADlU7gURGTO8xYUW7VB6gGMd0FLOG91mDuGF6dMC-Q3HZX92KLMBaBmq5_nWz14BkSU4cyc-d29b_KJWk3Y3UCS1vCt_FTCYlmy9v2gsY1ygcae9SMij6gVHdCv0JE1vOKElwMprKuaJ_C0Ji3X_9bzZ6FqvtAfGU4rB4oSIKgrmVc6BOMWYkIDq9bNbvglru_qjPcROstw-ZK5hOVOVX_O2bw-ipx-9aMvCCyucInRRWfmOplSi9L6Hzk1je9AhvkDwydSeSNs60-LcRAoV_41j_ocnkmqgLCkG_mywmRt7wJoRD1xw3WDztvRZqo0hq4FlW4sUEJMw4995iem0YllZpjy9Iirf1vQGx6ERcPkAYoawQmnypkHzII6Wa_M04F8hCCNN0xBtPKPpbswGTmheH8zoleve8H26Geqce2LrC78gTv_19RUWspkDeS7ghF7EY-KCcBRTL8QUptX-dolR-pRUu4JTtYtf8Pj2qcR31619g3-gTPyroY2hhFTbhAMV5i_JhaU_tMw73s7gpgforWR3TSaKn-USHqLB5IKnnZxXs28swS_heMZtjj43GTMAVI8ie6HhlTboxv_B-Vbjh1guJZTETB9qkEIiDvpN_MSnL7xr3nQHytQVUn_lbBF0yEqLNRdUW9PUoEbLCJs4W4IGCWX8WUqq2M-KFsskzwLYtdXBBoy-WSup1i9-W1m68uSRS6Qne50xI.Io6QdPS1ESg4ZYI0cgrFow'
    }

    axios.get('https://devcoreapi.prescreening.io/uiapi/api/AntiForgeryToken', { headers: headers })
      .then((response) => {
        console.log(response.data.Body)
        setToken(response.data.Body)

        var data = {
          "projectId": "75",
          "userId": 179,
          "requestIds": [
            "FR00088F"
          ],
          "DownloadType": "csv"
        };
    
        headers["X-XSRF-TOKEN"] = response.data.Body;
        axios.post('https://devcoreapi.prescreening.io/uiapi/api/DownloadFinnetReportByRequestId',data,{ headers: headers },{
          withCredentials: true
        })
          .then((response) => {
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });

      })
      .catch(function (error) {
        console.log(error);
      });

  }, [])

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route exact path="/home/*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
