import {Fragment, useEffect, useMemo, useState} from "react";
import Spinner from "../Loaders/Spinner";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useTheme } from '@mui/material/styles';
import {Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './dashboard.css'
import Button from "@mui/material/Button";
import Toast from "../Toaster/Toast";
import axios from "axios";
import { Chart as ChartJS,LinearScale,CategoryScale,BarElement,Title, ArcElement, Tooltip, Legend } from 'chart.js';
import {Doughnut, Bar} from 'react-chartjs-2';
import {DataFacadeGetProjectList, DataFacadeGetProjectStats} from "../EndPoint";
import {AgGridReact} from "ag-grid-react";
import Typography from "@mui/material/Typography";
// import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
// import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';
ChartJS.register(ArcElement, Tooltip, Legend,CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top'
        },
        title: {
            display: true,
            text: 'Check Bar Chart',
        },
    },
};

const names = [
    'PepCheck',
    'SanctionCheck',
    'ApcCheck',
    'AmlCheck',
    'Human Rights Violations',
    'Indian Watchlists',
    'Politically Exposed Individuals',
    'Suspected Shell Companies Check',
    'ICIJ Watchlist'
];
const labels = [
    'PEP',
    'Sanction',
    'Adverse Press Coverage',
    'AML Penalties',
    'Human Rights Violations',
    'Indian Watchlists',
    'Politically Exposed Individuals',
    'Suspected Shell Companies Check',
    'ICIJ Watchlist'
];
function getParams() {
    return {
        suppressQuotes: null,
        columnSeparator: null,
        customHeader: null,
        customFooter: null,
    };
}
function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
export default function DataFacadeDashboard() {
    const theme = useTheme();
    const [toast,setToastData] = useState({
        Show :false,
        Message:'',
        Status:''
    })
    const [doughnutData,setDoughnutData] = useState({})
    const [loading,setLoading] = useState(true)
    const [statsData,setStatsData] = useState({})
    const [totalHitCount,setTotalHitCount] = useState(0)
    const [barData,setBarData] = useState({})
    const [gridApi, setGridApi] = useState(null);
    const [projectData,setProjectData] = useState([])
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [dateData,setDateData] = useState({
        fromDate:`${new Date().getFullYear()}-${new Date().getMonth() + 1 <= 9 ?  `0${new Date().getMonth() + 1}`:
            (new Date().getMonth() + 1)}-${new Date().getDate() <= 9 ? `0${new Date().getDate()}`
            : new Date().getDate()}`,
        toDate:`${new Date().getFullYear()}-${new Date().getMonth() + 1 <= 9 ?  `0${new Date().getMonth() + 1}`:
            (new Date().getMonth() + 1)}-${new Date().getDate() <= 9 ? `0${new Date().getDate()}`
            : new Date().getDate()}`
    })
    const [inputData,setInputData] = useState({
        ProjectId:0,
        CheckNames:[]
    })
    const [checkData,setCheckData] = useState({})

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Check Count',
                data: [],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1
            }
        ]
    }

    const barChart = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: [],
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
        ]
    }
    const handleToChange = (newValue) => {
        let day = newValue.$D > 9 ? newValue.$D : `0${newValue.$D}`;
        let month = newValue.$M > 9 ? newValue.$M + 1 : `0${newValue.$M + 1}`;
        let year = newValue.$y;
        setDateData(data => ({
            ...data,
            toDate: `${year}-${month}-${day}`
        }))

    };
    const handleFromChange = (newValue) => {
        let day = newValue.$D > 9 ? newValue.$D : `0${newValue.$D}`;
        let month = newValue.$M > 9 ? newValue.$M + 1 : `0${newValue.$M + 1}`;
        let year = newValue.$y;
        setDateData(data => ({
            ...data,
            fromDate: `${year}-${month}-${day}`
        }))

    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onBtnExport = () => {
        const params = getParams();
        if (params.suppressQuotes || params.columnSeparator) {
            alert(
                'NOTE: you are downloading a file with non-standard quotes or separators - it may not render correctly in Excel.'
            );
        }
        gridApi.exportDataAsCsv(params);
    };
    function handleButtonClick(){
        setLoading(true)
        const payload = {
            ProjectId : inputData.ProjectId,
            CheckNames : inputData.CheckNames,
            fromDate: dateData.fromDate,
            toDate : dateData.toDate
        }
        axios.post(`${sessionStorage.getItem('DATA_FACADE')}${DataFacadeGetProjectStats}`,payload,{headers:headers})
            .then((res) =>
            {
                setLoading(false)
                setStatsData(res.data)
                setCheckData(res.data["Checks Data"])
                setTotalHitCount(res.data.TotalHits)
                chartData.datasets[0].data = [res.data.Total_PepCheck_Hits,res.data.Total_SanctionCheck_Hits,
                    res.data.Total_ApcCheck_Hits,res.data.Total_AmlCheck_Hits,res.data["Total_Human Rights Violations_Hits"],
                    res.data["Total_Indian Watchlists_Hits"]]
                barChart.datasets[0].data = [res.data.Total_PepCheck_Hits,res.data.Total_SanctionCheck_Hits,
                    res.data.Total_ApcCheck_Hits,res.data.Total_AmlCheck_Hits,res.data["Total_Human Rights Violations_Hits"],
                    res.data["Total_Indian Watchlists_Hits"]];
                setDoughnutData(chartData)
                setBarData(barChart)
            })
            .catch(err =>
            {
                setToastData(data => ({
                    Message: "No Data Found",
                    Show: true,
                    Status: "Red"
                }))
            })
        setTimeout(() =>
        {
            setToastData(data => ({
                Show: false
            }))
        },3000)
    }
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('DATAFACADE_TOKEN')
    }

    useEffect(() =>
    {
        axios.get(`${sessionStorage.getItem('DATA_FACADE')}${DataFacadeGetProjectList}`,{headers:headers})
            .then(res =>
            {
                setProjectData(res.data)
            })
            .catch(err =>
            {
                setToastData(data => ({
                    Message: "Something went wrong",
                    Show: true,
                    Status: "Red"
                }))
            })
        setTimeout(() =>
        {
            setToastData(data => ({
                Show: false
            }))
        },3000)
    },[])

    useEffect(() =>
    {
        if(inputData.ProjectId > 0)
        {
            setLoading(true)
            axios.post(`${sessionStorage.getItem('DATA_FACADE')}${DataFacadeGetProjectStats}`,inputData,{headers:headers})
                .then((res) =>
                {
                    setLoading(false)
                    setStatsData(res.data)
                    setCheckData(res.data["Checks Data"])
                    setTotalHitCount(res.data.TotalHits)
                    chartData.datasets[0].data = [res.data.Total_PepCheck_Hits,res.data.Total_SanctionCheck_Hits,
                        res.data.Total_ApcCheck_Hits,res.data.Total_AmlCheck_Hits,res.data["Total_Human Rights Violations_Hits"],
                        res.data["Total_Indian Watchlists_Hits"],res.data['Total_Politically Exposed Individuals_Hits']]
                    barChart.datasets[0].data = [res.data.Total_PepCheck_Hits,res.data.Total_SanctionCheck_Hits,
                        res.data.Total_ApcCheck_Hits,res.data.Total_AmlCheck_Hits,res.data["Total_Human Rights Violations_Hits"],
                        res.data["Total_Indian Watchlists_Hits"],res.data['Total_Politically Exposed Individuals_Hits']];
                    setDoughnutData(chartData)
                    setBarData(barChart)
                })
                .catch(err =>
                {
                    setToastData(data => ({
                        Message: "No Data Found",
                        Show: true,
                        Status: "Red"
                    }))
                })
        }
        setTimeout(() =>
        {
            setToastData(data => ({
                Show: false
            }))
        },3000)
    },[inputData])

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            editable : true
        };
    }, []);

    const [columnDefs] = useState([
        { field: 'caseId', headerName: 'CaseId', headerClass: 'text-center' },
        {field: 'entityName',headerName: 'Entity Name',headerClass: 'text-center'},
        {field: 'createdAt',headerName: 'CreatedAt',headerClass: 'text-center'},
        {field: 'createdBy',headerName: 'CreatedBy',headerClass: 'text-center'},
        {field: 'PepCheck',headerName: 'Pep',headerClass: 'text-center'},
        {field: 'SanctionCheck',headerName: 'Sanction',headerClass: 'text-center'},
        {field: 'Indian Watchlists',headerName: 'Indian Watchlists',headerClass: 'text-center'},
        {field: 'Politically Exposed Individuals', headerName : 'Politically Exposed Individuals',headerClass: 'text-center'},
        {field: 'TotalHitCount',headerName: 'TotalHitCount',headerClass: 'text-center'},
        {field: 'success',headerName: 'Success',headerClass: 'text-center'}
    ])

    return (
        <Fragment>
            <div className={'container-fluid'}>
                <div className='row'>
                    <div className='col-sm-3'>
                        <FormControl sx={{ width: 250 }}>
                            <Select
                                value={inputData.ProjectId}
                                onChange={e => setInputData(data => ({
                                    ...data,
                                    ProjectId: e.target.value
                                }))}
                                sx={{width:'250px'}}
                                labelId="project-label"
                                id="project-label"
                            >
                                <MenuItem value={0}>Select Project</MenuItem>
                                {projectData.map((demo) => <MenuItem key={demo.Id} value={demo.Id}>{demo.ProjectName}</MenuItem> )}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={'col-sm-3'}>
                        <FormControl sx={{width: 250 }}>
                            <InputLabel id="demo-multiple-chip-label">Select Check</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                value={inputData.CheckNames}
                                onChange={(e) => setInputData(data => ({
                                    ...data,
                                    CheckNames: e.target.value
                                }))}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {names.map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                        style={getStyles(name, inputData.CheckNames, theme)}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={'col-sm-3'}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                                label="From Date"
                                inputFormat="YYYY-MM-DD"
                                value={dayjs(dateData.fromDate)}
                                onChange={handleFromChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className={'col-sm-3'}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                                label="To Date"
                                inputFormat="YYYY-MM-DD"
                                value={dayjs(dateData.toDate)}
                                onChange={handleToChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className='row' style={{marginTop:'10px'}}>
                        <div className={'col-sm-10'}>

                        </div>
                        <div className={'col-sm-2'} style={{marginTop:'20px'}}>
                            <Button onClick={handleButtonClick} fullWidth={true} variant={'contained'}>Date Search</Button>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <Toast Message = {toast.Message} Show={toast.Show} Status = {toast.Status}/>
                </div>
            </div>
            <Divider sx={{marginTop:'10px'}}/>
            <div className={'container-fluid'} style={{marginTop:'10px'}}>
                {
                    loading ? <Spinner/>
                        :
                        <div className={'container-fluid'}>
                            <div className='row'>
                                <div className={'col-sm-4'}>
                                    <Doughnut data={doughnutData} />
                                </div>
                                <div className={'col-sm-4'}>

                                </div>
                                <div className={'col-sm-4'}>
                                    <Bar options={options} data={barData} />
                                </div>
                            </div>
                            <div className={'row'} style={{marginTop:'15px'}}>
                                <Divider/>
                            </div>
                            <div className={'row'} style={{marginTop:'10px'}}>
                                <div className={'col-sm-4'}>
                                </div>
                                <div className={'col-sm-4'}>
                                    <Typography>Total Hit Count : <b>{totalHitCount}</b></Typography>
                                </div>
                                <div className={'col-sm-4'}>
                                    <Button variant={'outlined'} onClick={() => onBtnExport()}>
                                        Export CSV
                                    </Button>
                                </div>
                            </div>
                            <div className={'row'} style={{marginTop:'10px'}}>
                                <div className="ag-theme-alpine" style={{ height: 400,width:'100%' }}>
                                    <AgGridReact
                                        animateRows={true}
                                        rowHeight={50}
                                        suppressCsvExport={false}
                                        defaultColDef={defaultColDef}
                                        rowData={checkData}
                                        onGridReady={onGridReady}
                                        columnDefs={columnDefs}>
                                    </AgGridReact>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </Fragment>
    )
}