import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MenuItem, Select} from '@mui/material';
import {Fragment, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import loginOne from '../LottieAnimations/home.json';
import loginTwo from '../LottieAnimations/login_one.json';
import Toast from "../Toaster/Toast";
import './signin.css'

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" target="_blank" href="https://app.prescreening.io">
                Presreening.io
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function SignIn() {
    const [env,SetEnv] = useState('Select env')
    const [loading,setLoading] = useState(false)
    const [userData,setUserData] = useState({
        userName:'',
        password:''
    })
    const [hide,setHide] = useState(false)
    const [toastData,setToastData] = useState({
        Message:"",
        Status:""
    });

    useEffect(() =>
    {
        document.body.classList.add('bodyColor')

        return () => {
            document.body.classList.remove('bodyColor');
        };
    },[])

    const navigate = useNavigate();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loginOne,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const defaultOptions1 = {
        loop: true,
        autoplay: true,
        animationData: loginTwo,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if(userData.userName !== 'admin.zigram.tech' || userData.password !=='admin.zigram@123')
        {
            setToastData(data => ({
                ...data,
                Message:"Access Denied",
                Status:"Red"
            }))
            setHide(true)
        }
        else {
            if(env === 'Select env')
            {
                setToastData(data => ({
                    ...data,
                    Message:"Please Select env",
                    Status:"Red"
                }))
                setHide(true)
            }
            else {
                //const data = new FormData(event.currentTarget);
                const payload = {
                    username: 'Y21sMFpYTm9MbTF2YUdGdVFIcHBaM0poYlM1MFpXTm8%3D',
                    password: 'Y21sMFpYTm9RREV5TXc9PQ%3D%3D',
                }
                const dfPayload = {
                    username: 'uAECAwB4fHw+8qO7xV/RedzhGtpkOeLuZ8prTngld82YQi0CKQwBARoNgqp8Mp96sDt10HpRdgAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDFLFPiZcVyJ2QeyYSgIBEIA7ByT5lI+nDqZ7VmgEsI5WoxskgiR+8zwE2d23XfEX17L8NznbNyyrxlt+kKR/Ze7xTKP8FzPbawW8Fg4McmjOvJV6ZBAoBpUBdmBZ9w+NEAZfQaWmLdEodiY5pkl0kedJy5Ln1Zi8FGbPsxQ=',
                    password: 'uAECAwB4fHw+8qO7xV/RedzhGtpkOeLuZ8prTngld82YQi0CKQwBejrequYX3zUrA8rlawgJ3QAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDOhAoKerQtSngeKfBAIBEIA7hCaWHj7g4orpWfk7lvmHllrFAW5HqgwolCTaFU74m9z074tETt5IHrPXqHqOwdsRDN4OpPD3ht5EwIBzpbMG6xEFLoNX/O3jmFSGTIsspczJL/EBL/63KkV2mE09Pe3PgNfI5+UGAIEq52E=',
                }
                setLoading(true)
                if(env === 'https://devcoreapi.prescreening.io/uiapi/api')
                {
                    sessionStorage.setItem("ENV_NAME","Development");
                    sessionStorage.setItem("DATA_FACADE",'https://datafacade-dev.prescreening.io')
                }
                else if(env === 'https://stagecoreapi.prescreening.io/uiapi/api')
                {
                    sessionStorage.setItem("ENV_NAME","Staging");
                    sessionStorage.setItem("DATA_FACADE",'https://datafacade-stage.prescreening.io')
                }
                else if(env === 'Syntizen')
                {
                    sessionStorage.setItem("ENV_NAME","Syntizen");
                    sessionStorage.setItem("DATA_FACADE",'http://syntizen-datafacade.prescreening.io')
                }
                else if (env === "https://qacoreapi.prescreening.io/uiapi/api")
                {
                    sessionStorage.setItem("ENV_NAME","Qa");
                    sessionStorage.setItem("DATA_FACADE",'https://datafacade-qa.prescreening.io')
                }
                else {
                    sessionStorage.setItem("ENV_NAME","Production");
                    sessionStorage.setItem("DATA_FACADE",'https://datafacade.prescreening.io')
                }
                axios.post(`${sessionStorage.getItem('DATA_FACADE')}/token`,dfPayload)
                    .then((res) =>
                    {
                        sessionStorage.setItem('DATAFACADE_TOKEN',`Bearer ${res.data.access_token}`);
                    })
                    .catch((err) =>
                    {
                        console.log(err)
                    })
                const endPointData = env === 'Syntizen' ? 'https://coreapi.prescreening.io/uiapi/api' : env
                axios.post(`${endPointData}/SignIn`,payload).then((res) =>
                {
                    sessionStorage.setItem("BASE_ENDPOINT",endPointData)
                    sessionStorage.setItem("token",`Bearer ${res.data.LoginDetails.access_token}`)
                    setLoading(false)
                    navigate('/home/project')
                })
                    .catch((err) =>
                    {
                        setLoading(false)
                        setToastData(data => ({
                            ...data,
                            Message:"Something went wrong",
                            Status:"Red"
                        }))
                        setHide(true)
                    })
            }
        }
        setTimeout(() =>
        {
            setHide(false)
        },3000)
    };

    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <div className='row'>
                    <div className='col-sm-4' style={{marginTop:'40px'}}>
                        <Lottie
                            options={defaultOptions}
                            height={400}
                            width={400}/>
                    </div>
                    <div className='col-sm-4'>
                        <Container component="main" className='mainCard' maxWidth="xs">
                            <CssBaseline />
                            <Box
                                sx={{
                                    marginTop: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                    <LockOutlinedIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    Sign in
                                </Typography>
                                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        value={userData.userName}
                                        id="email"
                                        onChange={e => setUserData(data => ({
                                            ...data,
                                            userName: e.target.value
                                        }))}
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        value={userData.password}
                                        onChange={e => setUserData(data => ({
                                            ...data,
                                            password: e.target.value
                                        }))}
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                    />
                                    <Select
                                        fullWidth
                                        labelId = "env-simple-select-label"
                                        id = "env-simple-select"
                                        value={env}
                                        onChange={e => SetEnv(e.target.value)}
                                    >
                                        <MenuItem value={'Select env'}>Select Env</MenuItem>
                                        <MenuItem value={'https://devcoreapi.prescreening.io/uiapi/api'}>Dev</MenuItem>
                                        <MenuItem value={'https://stagecoreapi.prescreening.io/uiapi/api'}>Staging</MenuItem>
                                        <MenuItem value={'https://coreapi.prescreening.io/uiapi/api'}>Prod</MenuItem>
                                        <MenuItem value={'https://qacoreapi.prescreening.io/uiapi/api'}>QA</MenuItem>
                                        <MenuItem value={'Syntizen'}>Syntizen</MenuItem>
                                    </Select>
                                    <LoadingButton
                                        type="submit"
                                        fullWidth
                                        loadingPosition="center"
                                        variant="contained"
                                        loading ={loading}
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Sign In
                                    </LoadingButton>
                                </Box>
                            </Box>
                            <Copyright sx={{ mt: 8, mb: 4 }} />
                        </Container>
                    </div>
                    <div className='col-sm-3' style={{marginTop:'40px'}}>
                        <Lottie
                            options={defaultOptions1}
                            height={400}
                            width={400}/>
                    </div>
                </div>
            </ThemeProvider>
            <Toast Message = {toastData.Message} Show = {hide} Status = {toastData.Status}/>
        </Fragment>
    );
}