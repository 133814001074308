import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Case from "./Case";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import TitleConfiguration from "./TitleConfiguration";
import ChecksConfig from "./ChecksConfig";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function ChecksPage() {
    const navigate = useNavigate();
    useEffect(() =>
    {
        if(sessionStorage.getItem('token') === null)
        {
            navigate('/')
        }
    },[])
    const theme = useTheme();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ bgcolor: 'background.paper'}}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Case" {...a11yProps(0)} />
                    <Tab label="Title Mapping" {...a11yProps(1)} />
                    <Tab label="Editor" {...a11yProps(2)} />
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                ></Tabs>
            </AppBar>
            <TabPanel value={value} index={0} dir={theme.direction}>
                <Case/>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                <TitleConfiguration/>
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
                <ChecksConfig/>
            </TabPanel>
        </Box>
    );
}