export const ProjectDetailsByUserId = "/ProjectDetails?userId=";
export const UserDetailsByProjectId = "/UserDetailsByProjectId?projectId=";
export const Roles = "/Roles?projectId=";
export const GetChecksData = "/GetChecksData?caseId=";
export const ListingHistoryWithChecks = "/ListingHistoryWithChecks?projectId="
export const DeleteDataByProjectId = "/DeleteDataByProjectId?projectId="
export const DeleteDataByBatchId = "/DeleteDataByBatchId?batchId="
export const DeleteDataByCaseId = "/DeleteDataByCaseId?caseId="
export const MapUserToProject = "/MapUserToProject"
export const ProjectSetting = "/ProjectSetting?projectId="
export const UpdatePasswordApi = "/UpdatePassword"
export const InsertTagAndTitle = "/InsertTagTitleMapping"
export const InsertPdfTagAndTitle = "/InsertPdfTagTitleMapping"
export const DeleteUserProjectRoleMapping = "/DeleteUserProjectRoleMapping?projectId="
export const DeleteProjectCheckMapping = "/DeleteProjectCheckMapping?projectId="
export const InsertProjectCheckMapping = "/InsertProjectCheckMapping?projectId="
export const DataFacadeMicroService="/GetMicroServiceSetting?userName=ritesh.mohan@zigram.tech&projectId="
export const DataFacadeGetProjectStats = "/GetProjectStats"
export const DataFacadeGetProjectList = "/GetProjectList"
export const GetCheckNameList = "/GetCheckNameList"
export const GetListByCheckName = "/GetListByCheckName"
export const AddListToProjectBucket = "/AddListToProjectBucket"
export const DeleteParticularList = "/DeleteParticularList"
export const  DeleteAllListByCheckName = '/DeleteAllListByCheckName'
export const MapAllListToProject = '/MapAllListToProject'
export const GetAllListName = '/GetListName'
export const AddUser = '/SignUp'
export const GetProjectList = "/GetProjectList?userId="
export const GetTierNames = "/GetTierNames"
export const UpdateCountryTierIdForCheck = "/UpdateCountryTierIdForCheck"
export const InsertTierWiseListMapping = "/InsertTierWiseListMapping"
export const GetQueueDetails = "/Queue"
export const UpdateWorkerStatus = "/UpdateWorkerStatus"
export const UpdateQueueConfiguration = "/UpdateQueueConfiguration"
export const GetRunningQueueConfiguration = "/GetRunningQueueConfiguration"
export const GetWorkerConfiguration = "/GetWorkerConfiguration"