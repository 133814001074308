import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";

export default function ProjectCard(props) {
    const deleteByProjectId = (event) => {
        let projectId = event.target.value;
        props.DeleteFunction(projectId)
    };
    const openSettingByProjectId = (event) => {
        const projectId = event.target.value;
        props.OpenSetting(projectId)
    }


    return (
        <div className='col-sm-6 col-lg-3 mb-3 mb-lg-5'>
            <Card sx={{ borderRadius: '8px' }}>
                <CardContent>
                    <img alt='Project Logo' src={`data:image/png;base64,${props.ProjectImage}`} height={100} />
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        <b>{props.ProjectName} :- {props.ProjectId}</b>
                    </Typography>
                </CardContent>
                <div className='row' style={{marginBottom:'20px'}}>
                    <div className='col-sm-6'>
                        <LoadingButton loadingPosition="center" loading={props.Delete} title="Delete the Project Data" variant="outlined" size="small" color='error' value={props.ProjectId} onClick={e => deleteByProjectId(e)}>
                            Delete Data
                        </LoadingButton>
                    </div>
                    <div className='col-sm-6'>
                        <LoadingButton loadingPosition="center" loading={props.Setting} title="Open project setting" variant="outlined" size="small" color='error' value={props.ProjectId} onClick={e => openSettingByProjectId(e)}>
                            Setting
                        </LoadingButton>
                    </div>
                </div>
            </Card>
        </div>
    );
}