import {Fragment, useEffect, useState,useMemo} from "react";
import axios from "axios";
import {GetProjectList, UpdatePasswordApi,UserDetailsByProjectId,AddUser} from "../EndPoint";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import Divider from "@mui/material/Divider";
import Spinner from "../Loaders/Spinner";
import {AgGridReact} from "ag-grid-react";
import {MenuItem, Modal, Select} from "@mui/material";
import './ag-grid.css'
import Button from "@mui/material/Button";
import Toast from "../Toaster/Toast";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const style = {
    position: 'absolute',
    borderRadius:'10px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function Users() {
    const [projectData, setProjectData] = useState([])
    const [userData,setUserData] = useState([])
    const [loading,setLoading] = useState(true)
    const [userSession,setSession] = useState('');
    const [userName,setUserName] = useState('');
    const [userPassword,setUserPassword] = useState('');
    const [projectId, setProjectId] = useState(0);
    const [snack,setSnackBar] = useState(false);
    const [message,setMessage] = useState('');
    const [toastStatus,setToastStatus] = useState('')
    const [modal,setModal] = useState(false);
    const [open,setOpen] = useState(false)
    const [addUserData , setAddUserData] = useState({
        firstName : '',
        lastName : '',
        userName : '',
        designation : '',
        department : '',
        company : '',
        projectId : '',
        applicationRoleId : ''
    });
    const [addUserLoading,setAddUserLoading] = useState(false)
    const [reload,setReload] = useState(false)

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
    }

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            editable : true
        };
    }, []);

    function encodeData(plainText)
    {
        var utf8 = escape(plainText);
        var encode1 = btoa(utf8);
        var encode2 = btoa(encode1);
        var escapeData = escape(encode2)
        return escapeData;
    }

    function generatePassword(userName,tempPassword)
    {
        var encodedUserName = encodeData(userName);
        var encodedPassword = encodeData(tempPassword);
        
        console.log("Hello",encodedUserName,encodedPassword);

        var payload ={
            username : encodedUserName,
            password : encodedPassword
        }
        axios.post(`${sessionStorage.getItem('BASE_ENDPOINT')}/SignIn`,payload)
            .then((res) =>
            {
                setLoading(false)
                if(res.data.StatusCode !== "BadRequest")
                {
                    setSession(res.data.Session)
                    setUserName(res.data.UserName)
                    setModal(true);
                }
                else {
                    setSnackBar(true)
                    setToastStatus('Red')
                    setMessage(res.data.Message)
                }
            })
            .catch((err) =>
            {
                setSnackBar(true)
                setToastStatus('Red')
                setMessage("Something went wrong")
            })
        setTimeout(() =>
        {
            setSnackBar(false)
        },3000)
    }
    const [columnDefs] = useState([
        {field: 'Id', headerName: 'User Id', headerClass: 'text-center' },
        {field: 'UserName',headerName: 'User Name',headerClass: 'text-center'},
        {field: 'Email',headerName: 'Email',headerClass: 'text-center'},
        {field: 'Company',headerName: 'Company',headerClass: 'text-center'},
        {
            headerName: "Password", headerClass: 'text-center',
            cellRenderer: (params) =>
            {
                if(params.data.CognitoStatus !== 'CONFIRMED')
                {
                    return (
                        <div>
                            <LoadingButton
                                loadingPosition="start"
                                variant="text"
                                onClick={() => generatePassword(params.data.Email,params.data.Industry)}
                            >
                                Generate
                            </LoadingButton>
                        </div>
                    )
                }
                else {
                    return (
                        <div>
                            <Button variant='text'>Generated</Button>
                        </div>
                    )
                }
            }
        }
    ])

    function UpdatePassword()
    {
        setModal(false)
        const payload = {
            Session:userSession,
            UserName:userName,
            NewPassword:userPassword
        }
        setLoading(true)
        axios.post(`${sessionStorage.getItem('BASE_ENDPOINT')}${UpdatePasswordApi}`,payload ,{ headers: headers })
            .then((res) => {
                axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${UserDetailsByProjectId}${projectId}`, { headers: headers })
                    .then((res) => {
                        setLoading(false)
                        setUserData(res.data.Body)
                    })
                    .catch((err) => {
                        setLoading(false)
                        setSnackBar(true)
                        setToastStatus('Red')
                        setMessage("Some thing went wrong")
                    })
            })
            .catch((err) => {
                setLoading(false)
                setSnackBar(true)
                setToastStatus('Red')
                setMessage("Some thing went wrong")
            })
        setTimeout(() =>
        {
            setSnackBar(false)
        },3000)
    }

    useEffect(() =>
    {
        setLoading(true)
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${UserDetailsByProjectId}${projectId}`, { headers: headers })
            .then((res) => {
                setLoading(false)
                setUserData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)})
    },[projectId,reload])

    useEffect(() =>
    {
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${GetProjectList}1`, { headers: headers })
            .then((res) => {
                setLoading(false)
                setProjectData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)
            })
    },[])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    function handleSubmit()
    {
        setAddUserLoading(true)
        axios.post(`${sessionStorage.getItem('BASE_ENDPOINT')}${AddUser}`,addUserData ,{ headers: headers })
            .then((res) => {
                if(res.status === 200)
                {
                    setAddUserLoading(false)
                    setSnackBar(true)
                    setToastStatus('Green')
                    setMessage(res.data.Message)
                    setAddUserData(data => ({
                        ...data,
                        firstName: '',
                        lastName: '',
                        userName: '',
                        projectId: '',
                        company: '',
                        department: '',
                        applicationRoleId: '',
                        designation: ''
                    }))
                    setOpen(false)
                    setReload(true)
                }
                else {
                    setAddUserLoading(false)
                    setSnackBar(true)
                    setToastStatus('Red')
                    setMessage("Something went wrong")
                }
            })
            .catch((err) => {
                setAddUserLoading(false)
                setSnackBar(true)
                setToastStatus('Red')
                setMessage("Something went wrong")
            })
        setTimeout(() =>
        {
            setSnackBar(false)
        },3000)
    }

    return(
        <Fragment>
            <div className='row'>
                <div className='col-sm-4'>
                    <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={projectId}
                        onChange={e => setProjectId(e.target.value)}
                    >
                        <MenuItem value={0}>Select ProjectId</MenuItem>
                        {
                            projectData.map((x,index) => <MenuItem key={index} value={x.ProjectId}>{x.ProjectName} ({x.ProjectId})</MenuItem>)
                        }
                    </Select>
                </div>
                <div className={'col-sm-4'}>

                </div>
                <div className={'col-sm-4'}>
                    <Button variant={'contained'} onClick={handleClickOpen} sx={{marginTop:'15px'}}>
                        Add User
                    </Button>
                </div>
            </div>
            <Divider sx={{marginTop:'10px',marginBottom:'10px'}}/>
            <div className='row'>
                {
                    loading ? <Spinner/> :
                    <div className="ag-theme-alpine" style={{ height: 400,width:'100%' }}>
                        <AgGridReact
                            animateRows={true}
                            rowHeight={50}
                            defaultColDef={defaultColDef}
                            rowData={userData}
                            columnDefs={columnDefs}>
                        </AgGridReact>
                    </div>
                }
                <Modal
                    open={modal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className='row'>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Generate your Password
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    label="Enter Password"
                                    variant="standard"
                                    color="secondary"
                                    onChange={e => setUserPassword(e.target.value)}
                                />
                            </Typography>
                        </div>
                        <Divider sx={{marginTop:'10px'}}/>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <Button onClick={UpdatePassword} fullWidth style={{marginTop:'15px'}} variant='contained'>Update</Button>
                            </div>
                            <div className='col-sm-1'>
                                <Button onClick={e => setModal(false)} style={{marginTop:'15px'}} variant='outlined'>Close</Button>
                            </div>
                        </div>
                    </Box>
                </Modal>
                <Toast Message = {message} Show={snack} Status = {toastStatus}/>
            </div>
            <div className={'row'}>
                <Dialog open={open} onClose={handleClose} fullWidth={true}>
                    <DialogTitle>Add User</DialogTitle>
                    <DialogContent>
                        <div className={'row'}>
                            <div className={'col-sm-6'}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name={'firstname'}
                                    label="First Name"
                                    value={addUserData.firstName}
                                    onChange={(e) => setAddUserData(data => ({...data,firstName: e.target.value}))}
                                    type="text"
                                    fullWidth
                                    required = {true}
                                    variant="outlined"
                                />
                            </div>
                            <div className={'col-sm-6'}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name={'lastname'}
                                    label="Last Name"
                                    type="text"
                                    fullWidth
                                    required = {true}
                                    value={addUserData.lastName}
                                    onChange={(e) => setAddUserData(data => ({...data,lastName: e.target.value}))}
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-sm-6'}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name={'email'}
                                    label="Email Id"
                                    type="email"
                                    fullWidth
                                    required = {true}
                                    value={addUserData.userName}
                                    onChange={(e) => setAddUserData(data => ({...data,userName: e.target.value}))}
                                    variant="outlined"
                                />
                            </div>
                            <div className={'col-sm-6'}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name={'designation'}
                                    label="Designation"
                                    type="text"
                                    fullWidth
                                    required = {true}
                                    value={addUserData.designation}
                                    onChange={(e) => setAddUserData(data => ({...data,designation: e.target.value}))}
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-sm-6'}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name={'department'}
                                    label="Department"
                                    type="text"
                                    fullWidth
                                    required = {true}
                                    value={addUserData.department}
                                    onChange={(e) => setAddUserData(data => ({...data,department: e.target.value}))}
                                    variant="outlined"
                                />
                            </div>
                            <div className={'col-sm-6'}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Company"
                                    name={'company'}
                                    type="text"
                                    fullWidth
                                    required = {true}
                                    value={addUserData.company}
                                    onChange={(e) => setAddUserData(data => ({...data,company: e.target.value}))}
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-sm-6'}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name={'projectId'}
                                    label="Project Id"
                                    type="text"
                                    fullWidth
                                    required = {true}
                                    value={addUserData.projectId}
                                    onChange={(e) => setAddUserData(data => ({...data,projectId: e.target.value}))}
                                    variant="outlined"
                                />
                            </div>
                            <div className={'col-sm-6'}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Role Id"
                                    name={'roleId'}
                                    type="text"
                                    required = {true}
                                    fullWidth
                                    value={addUserData.applicationRoleId}
                                    onChange={(e) => setAddUserData(data => ({...data,applicationRoleId: e.target.value}))}
                                    variant="outlined"
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <LoadingButton
                            loadingPosition="center"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={!addUserData.firstName || !addUserData.lastName || !addUserData.department || !addUserData.company
                                || !addUserData.designation || !addUserData.userName || !addUserData.projectId || !addUserData.applicationRoleId}
                            loading={addUserLoading}
                        >
                            Submit
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div>
        </Fragment>
    )
}