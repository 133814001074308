import React, { Fragment } from 'react'
import {useEffect, useState} from "react";
import axios from 'axios'
import {MenuItem, Select} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Divider from "@mui/material/Divider";
import Toast from "../Toaster/Toast";
import Alert from "@mui/material/Alert";
import {
    GetCheckNameList,
    GetProjectList,
    GetTierNames,
    UpdateCountryTierIdForCheck,
    InsertTierWiseListMapping
} from "../EndPoint";

function TierWiseMapping() {
    const [projectId, setProjectId] = useState(0);
    const [checkName, setCheckName] = useState(0)
    const [tierId,setTierId] = useState(0)
    const [projectData ,setProjectData] = useState([])
    const [checkData ,setCheckData] = useState([])
    const [tierData ,setTierData] = useState([])
    const [toastData, setToastData] = useState({
        Message: "",
        Status: ""
    });
    const [hide, setHide] = useState(false)
    const [countryLoading,setCountryLoading] = useState(false)
    const [listLoading,setListLoading] = useState(false)

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
    }

    useEffect(() =>
    {
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${GetProjectList}1`, { headers: headers })
            .then((res) => {
                setProjectData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)
            })
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${GetCheckNameList}`, { headers: headers })
            .then((res) => {
                setCheckData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)
            })
        axios.get(`${sessionStorage.getItem('BASE_ENDPOINT')}${GetTierNames}`, { headers: headers })
            .then((res) => {
                setTierData(res.data.Body)
            })
            .catch((err) => {
                console.log(err)
            })
    },[])

    function mapCountryTier() {
        setCountryLoading(true)
        axios.put(`${sessionStorage.getItem('BASE_ENDPOINT')}${UpdateCountryTierIdForCheck}?projectId=${projectId}&tierId=${tierId}`, {},{ headers: headers })
            .then((res) => {
                setCountryLoading(false)
                setToastData(data => ({
                    ...data,
                    Message: res.data.Message,
                    Status: "Green"
                }))
                setHide(true)
            })
            .catch((err) => {
                setToastData(data => ({
                    ...data,
                    Message: 'Something went wrong',
                    Status: "Red"
                }))
                setHide(true)
                setCountryLoading(false)
            })
        setTimeout(() => {
            setHide(false)
        }, 3000)
    }
    function mapListTier(){
        setListLoading(true)
        var payload = {
            projectId : projectId,
            tierId : tierId,
            checkName : checkName
        }
        axios.post(`${sessionStorage.getItem('BASE_ENDPOINT')}${InsertTierWiseListMapping}`, payload, { headers: headers })
            .then((res) => {
                setListLoading(false)
                setToastData(data => ({
                    ...data,
                    Message: res.data.Message,
                    Status: "Green"
                }))
                setHide(true)
            })
            .catch((err) => {
                setListLoading(false)
                setToastData(data => ({
                    ...data,
                    Message: 'Something went wrong',
                    Status: "Red"
                }))
                setHide(true)
            })
        setTimeout(() => {
            setHide(false)
        }, 3000)
    }

  return (
    <Fragment>
        <div className='row'>
                <div className='col-sm-3'>
                    <Select
                        sx={{ width: '300px' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={projectId}
                        onChange={e => setProjectId(e.target.value)}
                    >
                        <MenuItem value={0}>Select Project</MenuItem>
                        {
                            projectData.map((x, index) => <MenuItem key={index} value={x.ProjectId}>{x.ProjectName} ({x.ProjectId})</MenuItem>)
                        }
                    </Select>
                </div>
                <div className={'col-sm-3'}>
                    <Select
                        value={checkName}
                        onChange={(e) => setCheckName(e.target.value)}
                        sx={{ width: '300px' }}
                        labelId="Check Name"
                        id="check-name"
                    >
                        <MenuItem value={0}>Select Check</MenuItem>
                        {
                            checkData.map((x, index) => <MenuItem key={index} value={x.CheckName}>{x.CheckName}</MenuItem>)
                        }
                    </Select>
                </div>
                <div className={'col-sm-3'}>
                    <Select
                        value={tierId}
                        onChange={(e) => setTierId(e.target.value)}
                        sx={{ width: '300px' }}
                        labelId="Tier Name"
                        id="tier-name"
                    >
                        <MenuItem value={0}>Select Tier</MenuItem>
                        {
                            tierData.map((x, index) => <MenuItem key={index} value={x.Id}>{x.TierName}</MenuItem>)
                        }
                    </Select>
                </div>
            </div>
            <Divider sx={{marginTop:'10px'}}/>
            <div className={'row'} style={{ marginTop: '25px' }}>
                    <Alert
                        severity="info">Select Project and Tier and hit the Map Country Tier Button.
                        <LoadingButton
                            onClick={mapCountryTier}
                            sx={{ marginLeft: '10px' }}
                            loadingPosition="center"
                            variant="outlined"
                            loading={countryLoading}
                            disabled={!projectId || !tierId}
                        >
                            Map Country Tier
                        </LoadingButton>
                    </Alert>
                </div>
                <div className={'row'} style={{ marginTop: '10px' }}>
                    <Alert
                        severity="info">
                        Select Project , Check Name and Tier to map the list based upon tier.
                        <LoadingButton
                            sx={{ marginLeft: '10px' }}
                            loadingPosition="center"
                            variant="outlined"
                            onClick={mapListTier}
                            disabled={!tierId || !projectId || !checkName}
                            loading={listLoading}
                        >
                            Map List Tier
                        </LoadingButton>

                    </Alert>
                </div>
                <div className='row'>
                    <Toast Message={toastData.Message} Show={hide} Status={toastData.Status} />
                </div>
    </Fragment>
  )
}

export default TierWiseMapping