import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link, Route, Routes, useNavigate } from 'react-router-dom'
import './home.css'
import CheckIcon from '@mui/icons-material/Check';
import Project from '../ProjectPage/Project';
import {useEffect, useState} from 'react';
import ChecksPage from '../Checks/ChecksPage'
import BatchData from "../BatchProcessing/BatchData";
import DataObjectIcon from '@mui/icons-material/DataObject';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import DataFacadeMain from "../DataFacade/DataFacadeMain";
import MemoryIcon from '@mui/icons-material/Memory';
import ListIcon from '@mui/icons-material/List';
import ListMapping from "../ListMapping/ListMapping";
import EngineeringIcon from '@mui/icons-material/Engineering';
import Worker from '../Worker/Worker';

const drawerWidth = 240;

export default function Home() {
  const { window } = Window;
  const [mobileOpen, setMobileOpen] = useState(false);

  const navigate = useNavigate();
  useEffect(() =>
  {
    if(sessionStorage.getItem('token') === null)
    {
      navigate('/')
    }
  },[])

    function handleLogout()
    {
        sessionStorage.clear();
        navigate('/')
    }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar/>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{color:'black'}}>
              <AssessmentIcon />
            </ListItemIcon>
            <Link to="project" style={{ color: 'black', textDecoration: 'none' }}>Project</Link>
          </ListItemButton>
        </ListItem>
          <ListItem disablePadding>
              <ListItemButton>
                  <ListItemIcon sx={{color:'black'}}>
                      <ListIcon />
                  </ListItemIcon>
                  <Link to="listmapping" style={{ color: 'black', textDecoration: 'none' }}>List Mapping</Link>
              </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
              <ListItemButton>
                  <ListItemIcon sx={{color:'black'}}>
                      <CheckIcon />
                  </ListItemIcon>
                  <Link to="checks" style={{ color: 'black', textDecoration: 'none' }}>Checks</Link>
              </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
              <ListItemButton>
                  <ListItemIcon sx={{color:'black'}}>
                      <DataObjectIcon />
                  </ListItemIcon>
                  <Link to="batch" style={{ color: 'black', textDecoration: 'none' }}>Batch</Link>
              </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
              <ListItemButton>
                  <ListItemIcon sx={{color:'black'}}>
                      <MemoryIcon />
                  </ListItemIcon>
                  <Link to="datafacade" style={{ color: 'black', textDecoration: 'none' }}>Data Facade</Link>
              </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
              <ListItemButton>
                  <ListItemIcon sx={{color:'black'}}>
                      <EngineeringIcon />
                  </ListItemIcon>
                  <Link to="worker" style={{ color: 'black', textDecoration: 'none' }}>Worker</Link>
              </ListItemButton>
          </ListItem>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
              {sessionStorage.getItem('ENV_NAME')}
          </Typography>
            <IconButton
                onClick={handleLogout}
                title="Logout"
                color="inherit"
                edge="end"
                sx={{ position:'absolute',right:'30px' }}
            >
                <PowerSettingsNewIcon />
            </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            background: 'white',
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Routes>
          <Route exact path='/project' element={<Project />} />
            <Route exact path='/listmapping' element={<ListMapping />} />
            <Route exact path='/checks' element={<ChecksPage/>} />
            <Route exact path='/batch' element={<BatchData/>} />
            <Route exact path='/datafacade' element={<DataFacadeMain/>}/>
            <Route exact path='/worker' element={<Worker/>}/>
        </Routes>
      </Box>
    </Box>
  );
}
