import {Fragment, useEffect, useMemo, useState} from "react";
import Spinner from "../Loaders/Spinner";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {AgGridReact} from "ag-grid-react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import {DataFacadeGetProjectList, DataFacadeMicroService} from "../EndPoint";
import Divider from "@mui/material/Divider";
import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/en';
import {FormControl, MenuItem, Select} from "@mui/material";

export default function MicroService() {
    const [open, setOpen] = useState(false);
    const [apiBtn,setApiBtn] = useState(false)
    const [apiData,setApiData] = useState({
        endpoint:'',
        inputData:{},
        headers:{},
        outputData:{}
    })
    const [loading,setLoading] = useState(true);
    const [btnLoading,setBtnLoading] = useState(false)
    const [projectId,setProjectId] = useState(0)
    const [microService,setMicroServiceData] = useState([])
    const [projectData,setProjectData] = useState([])

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('DATAFACADE_TOKEN')
    }

    useEffect(() =>
    {
        axios.get(`${sessionStorage.getItem('DATA_FACADE')}${DataFacadeGetProjectList}`,{headers:headers})
            .then(res =>
            {
                setProjectData(res.data)
            })
            .catch(err =>
            {
                console.log(err)
            })
    },[])
    useEffect(() =>
    {
        if(projectId > 0)
        {
            setBtnLoading(true)
            axios.get(`${sessionStorage.getItem('DATA_FACADE')}${DataFacadeMicroService}${projectId}`)
                .then((res) => {
                    setBtnLoading(false)
                    setLoading(false)
                    setMicroServiceData(res.data)
                })
                .catch((err) => {
                    setBtnLoading(false)
                    console.log(err)
                })
        }

    },[projectId])
    function handleSendClick()
    {
        setApiBtn(true)

        axios.post(apiData.endpoint,apiData.inputData,{headers:apiData.headers})
            .then((res) =>
            {
                setApiBtn(false)
                setApiData(data => ({
                    ...data,
                    outputData: res.data
                }))
            })
            .catch((err) =>
            {
                setApiBtn(false)
                console.log(err)
            })
    }
    function openDialog(params)
    {
        setApiData(data => ({
            ...data,
            endpoint: params.CheckEndPoint,
            inputData: JSON.parse(params.BodyData),
            headers: JSON.parse(params.HeaderMapping),
            outputData: {}
        }))
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false);
    };
    function handleProjectChange()
    {
        setBtnLoading(true)
        axios.get(`${sessionStorage.getItem('DATA_FACADE')}${DataFacadeMicroService}${projectId}`)
            .then((res) => {
                setBtnLoading(false)
                setLoading(false)
                setMicroServiceData(res.data)
            })
            .catch((err) => {
                setBtnLoading(false)
                console.log(err)
            })
    }

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            editable : true
        };
    }, []);

    const [columnDefs] = useState([
        { field: 'ProjectId', headerName: 'ProjectId', headerClass: 'text-center' },
        {field: 'CheckName',headerName: 'Check Name',headerClass: 'text-center'},
        {field: 'SearchType',headerName: 'Search Type',headerClass: 'text-center'},
        {field: 'CheckEndPoint',headerName: 'CheckEndPoint',headerClass: 'text-center'},
        {field: 'ResultCount',headerName: 'ResultCount',headerClass: 'text-center'},
        {
            headerName: "Test", headerClass: 'text-center',
            cellRenderer: (params) =>
            {
                return (
                    <div>
                        <Button
                            loadingPosition="center"
                            variant="outlined"
                            onClick={() => openDialog(params.data)}
                        >
                            Test
                        </Button>
                    </div>

                )
            }
        }
    ])

    return (
        <Fragment>
            <div className='row'>
                <div className='col-sm-3'>
                    <FormControl sx={{ width: 300 }}>
                        <Select
                            value={projectId}
                            onChange={e => setProjectId(e.target.value)}
                            sx={{width:'300px'}}
                            labelId="project-label"
                            id="project-label"
                        >
                            <MenuItem value={0}>Select Project</MenuItem>
                            {projectData.map((demo) => <MenuItem key={demo.Id} value={demo.Id}>{demo.ProjectName}</MenuItem> )}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className='row' style={{marginTop:'12px'}}>
                {
                    loading ? <Spinner/>
                        :
                        <div className="ag-theme-alpine" style={{ height: 400,width:'100%' }}>
                            <AgGridReact
                                animateRows={true}
                                rowHeight={50}
                                defaultColDef={defaultColDef}
                                rowData={microService}
                                columnDefs={columnDefs}>
                            </AgGridReact>
                        </div>
                }
            </div>
            <div className='row'>
                <Dialog
                    fullWidth={true}
                    maxWidth='lg'
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>Api Testing</DialogTitle>
                    <DialogContent>
                        <div className='row' style={{marginLeft:'10px'}}>
                            <div className={'col-sm-10'}>
                                <TextField value={apiData.endpoint} fullWidth={true} id="standard-basic" label="EndPoint" variant="standard" />
                            </div>
                            <div className={'col-sm-1'}>
                                <LoadingButton
                                    onClick={handleSendClick}
                                    loadingPosition={"center"}
                                    fullWidth={true}
                                    loading={apiBtn}
                                    variant={"contained"}
                                    >
                                    Send
                                </LoadingButton>
                            </div>
                        </div>
                        <div className={'row'} style={{marginTop:'10px',marginLeft:'10px'}}>
                            <div className={'col-sm-4'}>
                                <JSONInput
                                    colors={{
                                        string: "#DAA520" // overrides theme colors with whatever color value you want
                                    }}
                                    id          = 'inputJson'
                                    placeholder = { apiData.inputData }
                                    locale      = { locale }
                                    onChange={e => setApiData(data => ({
                                        ...data,
                                        inputData: e.jsObject
                                    }))}
                                    height      = '400px'
                                />
                            </div>
                            <div className={'col-sm-2'}>
                                <Divider orientation="vertical" sx={{borderColor:'black',borderRightWidth:'thick'}} />
                            </div>
                            <div className={'col-sm-6'}>
                                <JSONInput
                                    colors={{
                                        string: "#DAA520" // overrides theme colors with whatever color value you want
                                    }}
                                    id          = 'outputJson'
                                    placeholder = { apiData.outputData }
                                    locale      = { locale }
                                    height      = '400px'
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Fragment>
    )
}