import {Fragment, useState} from "react";
import ReactJson from 'react-json-view'
import Button from "@mui/material/Button";
export default function ChecksConfig() {
    const [editorData,setEditorData] = useState('')
    const [jsonData,setJsonData] = useState({})

    function handleChange(event)
    {
        let formattedData = `{${editorData}}`
        try{
            setJsonData(JSON.parse(formattedData))
        }
        catch(err){
            setJsonData(JSON.parse(editorData))
        }

    }

    return (
        <Fragment>
            <div className='row'>
                <div className='col-sm-5'>
                    <textarea
                        value={editorData}
                        onChange={e => setEditorData(e.target.value)}
                        placeholder="Enter your raw json."
                        style={{ width: 500,resize:'none !important',height:'500px'}}
                    />
                </div>
                <div className='col-sm-2'>
                    <Button variant={"contained"} onClick={handleChange}>Parse</Button>
                </div>
                <div className='col-sm-5'>
                    <ReactJson style={{height:'500px',overflowY:'scroll'}} src={jsonData} />
                </div>
            </div>
        </Fragment>
    )
}