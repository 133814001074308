import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert'

export default function Toast(props) {
    return (
        <Snackbar
            anchorOrigin={{ vertical:'top',horizontal:'center' }}
            open={props.Show}
            autoHideDuration={3000}
        >
            {props.Status === "Green" ? <Alert severity="success">{props.Message}</Alert>
            :
                <Alert severity="error">{props.Message}</Alert>
            }
        </Snackbar>
    )
}