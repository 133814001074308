import {Fragment, useState} from "react";
import TextField from "@mui/material/TextField";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import Toast from "../Toaster/Toast";
import * as React from "react";
import axios from "axios";
import {InsertPdfTagAndTitle, InsertTagAndTitle} from "../EndPoint";

export default function TitleConfiguration() {
    const [toast,setToastData] = useState({
        Show :false,
        Message:'',
        Status:''
    })
    const [btnLoadings,setBtnLoadings] = useState({
        first:false,
        second : false
    })
    const [viewMapping,setViewMapping] = useState({
        viewCheckName:'',
        tag:'',
        title:''
    })
    const [pdfMapping,setPdfMapping] = useState({
        pdfCheckName:'',
        tag:'',
        title:'',
        stage:''
    })

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('token')
    }

    function handleViewSubmit(){
        setBtnLoadings(data => ({
            ...data,
            first: true
        }))

        var tag = viewMapping.tag.split(',');
        var title = viewMapping.title.split(',')
        if(tag.length === title.length)
        {
            const payload ={
                TypeName:viewMapping.viewCheckName,
                Tags:tag,
                Title:title
            }
            axios.post(`${sessionStorage.getItem('BASE_ENDPOINT')}${InsertTagAndTitle}`,payload,{headers:headers})
                .then((res) =>{
                    setBtnLoadings(data => ({
                        ...data,
                        first: false
                    }))
                    setToastData(data => ({
                        ...data,
                        Show: true,
                        Message: res.data,
                        Status: 'Green'
                    }))
                })
                .catch((err) =>
                {
                    setBtnLoadings(data => ({
                        ...data,
                        first: false
                    }))
                    setToastData(data => ({
                        ...data,
                        Show: true,
                        Message: "Something went wrong",
                        Status: 'Red'
                    }))
                })
        }
        else {
            setBtnLoadings(data => ({
                ...data,
                first: false
            }))
            setToastData(data => ({
                ...data,
                Show: true,
                Message: "Length of all the fields should be equal",
                Status: 'Red'
            }))
        }

        setTimeout(() =>
        {
            setToastData(data => ({
                ...data,
                Show: false
            }));
        },3000)
    }
    function handlePdfSubmit()
    {
        setBtnLoadings(data => ({
            ...data,
            second: true
        }))

        var tag = pdfMapping.tag.split(',');
        var title = pdfMapping.title.split(',')
        var stage = pdfMapping.stage.split(',')
        if(tag.length === title.length && (tag.length === stage.length) && (title.length === stage.length))
        {
            const payload ={
                TypeName:pdfMapping.pdfCheckName,
                Tags:tag,
                Title:title,
                Stage:stage
            }
            axios.post(`${sessionStorage.getItem('BASE_ENDPOINT')}${InsertPdfTagAndTitle}`,payload,{headers:headers})
                .then((res) =>{
                    setBtnLoadings(data => ({
                        ...data,
                        second: false
                    }))
                    setToastData(data => ({
                        ...data,
                        Show: true,
                        Message: res.data,
                        Status: 'Green'
                    }))
                })
                .catch((err) =>
                {
                    setBtnLoadings(data => ({
                        ...data,
                        second: false
                    }))
                    setToastData(data => ({
                        ...data,
                        Show: true,
                        Message: "Something went wrong",
                        Status: 'Red'
                    }))
                })
        }
        else {
            setBtnLoadings(data => ({
                ...data,
                second: false
            }))
            setToastData(data => ({
                ...data,
                Show: true,
                Message: "Length of all the fields should be equal",
                Status: 'Red'
            }))
        }

        setTimeout(() =>
        {
            setToastData(data => ({
                ...data,
                Show: false
            }));
        },3000)
    }
    return (
        <Fragment>
            <div className='row'>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Alert severity="success">Tag and Title Mapping for Views.</Alert>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='row' style={{marginTop:'10px'}}>
                            <div className='col-sm-6'>
                                <TextField
                                    fullWidth
                                    label="Enter Check Name"
                                    variant="standard"
                                    value={viewMapping.viewCheckName}
                                    onChange={e => setViewMapping(data => ({
                                        ...viewMapping,
                                        viewCheckName: e.target.value
                                    }))}
                                    color="secondary"
                                />
                            </div>
                            <div className='col-sm-6'>
                                <TextField
                                    fullWidth
                                    label="Enter Comma Separated Tag Name"
                                    variant="standard"
                                    color="secondary"
                                    value={viewMapping.tag}
                                    onChange={e => setViewMapping(data => ({
                                        ...viewMapping,
                                        tag: e.target.value
                                    }))}
                                />
                            </div>
                        </div>
                        <div className='row' style={{marginTop:'10px'}}>
                            <div className='col-sm-6'>
                                <TextField
                                    fullWidth
                                    label="Enter Comma Separated Title Name"
                                    variant="standard"
                                    color="secondary"
                                    value={viewMapping.title}
                                    onChange={e => setViewMapping(data => ({
                                        ...viewMapping,
                                        title: e.target.value
                                    }))}
                                />
                            </div>
                        </div>
                        <div className='row' style={{marginTop:'10px'}}>
                            <div className='col-sm-1'>
                                <LoadingButton
                                    sx = {{marginTop:'12px'}}
                                    loadingPosition="center"
                                    variant="contained"
                                    loading ={btnLoadings.first}
                                    onClick={handleViewSubmit}
                                >
                                    Submit
                                </LoadingButton>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className='row' style={{marginTop:'10px'}}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Alert severity="success">Tag and Title Mapping for Pdf.</Alert>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='row' style={{marginTop:'10px'}}>
                            <div className='col-sm-6'>
                                <TextField
                                    fullWidth
                                    label="Enter Check Name"
                                    variant="standard"
                                    color="secondary"
                                    value={pdfMapping.pdfCheckName}
                                    onChange={e => setPdfMapping(data => ({
                                        ...pdfMapping,
                                        pdfCheckName: e.target.value
                                    }))}
                                />
                            </div>
                            <div className='col-sm-6'>
                                <TextField
                                    fullWidth
                                    label="Enter Comma Separated Tag Name"
                                    variant="standard"
                                    color="secondary"
                                    value={pdfMapping.tag}
                                    onChange={e => setPdfMapping(data => ({
                                        ...pdfMapping,
                                        tag: e.target.value
                                    }))}
                                />
                            </div>
                        </div>
                        <div className='row' style={{marginTop:'10px'}}>
                            <div className='col-sm-6'>
                                <TextField
                                    fullWidth
                                    label="Enter Comma Separated Title Name"
                                    variant="standard"
                                    color="secondary"
                                    value={pdfMapping.title}
                                    onChange={e => setPdfMapping(data => ({
                                        ...pdfMapping,
                                        title: e.target.value
                                    }))}
                                />
                            </div>
                            <div className='col-sm-6'>
                                <TextField
                                    fullWidth
                                    label="Enter Comma Separated Stage Name e.g(Pre,Post)"
                                    variant="standard"
                                    color="secondary"
                                    value={pdfMapping.stage}
                                    onChange={e => setPdfMapping(data => ({
                                        ...pdfMapping,
                                        stage: e.target.value
                                    }))}
                                />
                            </div>
                        </div>
                        <div className='row' style={{marginTop:'10px'}}>
                            <div className='col-sm-1'>
                                <LoadingButton
                                    sx = {{marginTop:'12px'}}
                                    loadingPosition="center"
                                    variant="contained"
                                    loading ={btnLoadings.second}
                                    onClick={handlePdfSubmit}
                                >
                                    Submit
                                </LoadingButton>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className={'row'}>
                <Toast Message = {toast.Message} Show={toast.Show} Status = {toast.Status}/>
            </div>
        </Fragment>
    )
}